<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
        aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
        style="margin: auto;">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    Informasi Data Layer
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                    <div class="row">
                        <div class="col-sm-12">
                            <label class="text-primary">Nama Layer:</label> {{ namalayer }}
                        </div>
                        <div class="col-sm-12">
                            <label class="text-primary">Meta Data:</label>
                            <span v-html="metadata"></span>
                        </div>
                        <div class="col-sm-12">
                            <label class="text-primary"><a :href="listnyageojson" target="_blank">Lihat API Peta (format geojson WGS 1984)</a></label>
                        </div>
                    </div>
                    </p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Tutup</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumbpeta">

    </div>
    <!-- Header End -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <input type="hidden" class="form-control form-control-sm" id="geojson" name="geojson"
                        placeholder="geojson">
                    <input type="hidden" class="form-control form-control-sm" id="NamaFile" name="NamaFile"
                        placeholder="NamaFile">
                    <input type="hidden" class="form-control form-control-sm" id="detail" name="detail"
                        placeholder="Detail">
                    <input type="hidden" class="form-control form-control-sm" id="Latitude" name="Latitude"
                        placeholder="Latitude">
                    <input type="hidden" class="form-control form-control-sm" id="Longitude" name="Longitude"
                        placeholder="Longitude">
                    <button type="button" class="btn btn-tool" id="bukamodalmetadata" @click="bukamodal()"
                        style="display: none;"><i class="fas fa-minus text-white"></i></button>
                    <button type="button" class="btn btn-tool" id="kosong" @click="datakosong()"
                        style="display: none;"><i class="fas fa-minus text-white"></i></button>
                    <button type="button" class="btn btn-tool" id="koordinat" @click="koordinatkosong()"
                        style="display: none;"><i class="fas fa-minus text-white"></i></button>
                    <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 95vh;" frameborder="0"
                        id="maps" scrolling="no" @load="loadpeta()"></iframe>
                </div>
            </div>
        </div>
    </div>
    <!-- Header End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: '',
            sourcenyamapdetails: '',
            namalayer: '',
            geojson: '',
            listnyageojson: '',
            metadata: '',
            datapesan: '',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        };
    },
    methods: {
        async bukamodal() {
            this.halamanloading = true;
            this.namalayer = document.getElementById('NamaFile').value;
            this.geojson = document.getElementById('geojson').value;
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetMetadata?random=" + random + "&geo=" + this.geojson).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.metadata = '';
                        this.metadata = Response.data.content.data[0].metadata;
                        this.listnyageojson = process.env.VUE_APP_URL_API+"maps_parastapainnovation-ViewGeojson?f=" + this.geojson;
                        $('#costumModaldisclaimer').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.datapesan = '';
                        this.metadata = '';
                        this.halamanloading = false;
                    }
                    
                }
            ).catch(error => {
                this.datapesan = '';
                this.metadata = '';
                this.halamanloading = false;
            });

            
        },
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya + '|' + this.secretencData;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        koordinatkosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Koordinat belum diisi..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Layer Gagal Diunduh..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
    },
    mounted() {
        this.halamanloading = true;
    },
}
</script>

<style></style>