<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'Aduan'">
    <Aduan/>
  </div>
  <div class="content" v-if="route.name == 'ITR'">
    <ITR/>
  </div>
  <div class="content" v-if="route.name == 'RDTR'">
    <RDTR/>
  </div>
  <div class="content" v-if="route.name == 'CetakITR'">
    <CetakITR/>
  </div>
  <div class="content" v-if="route.name == 'CetakRDTR'">
    <CetakRDTR/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'NSWI'">
    <NSWI/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRegulasi'">
    <DaftarRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'DaftarKetentuanUmum'">
    <DaftarKetentuanUmum/>
  </div>
  <div class="content" v-if="route.name == 'DaftarKetentuanKhusus'">
    <DaftarKetentuanKhusus/>
  </div>
  <div class="content" v-if="route.name == 'DaftarKetentuanKhususRDTR'">
    <DaftarKetentuanKhususRDTR/>
  </div>
  <div class="content" v-if="route.name == 'DaftarPengaturanZonasi'">
    <DaftarPengaturanZonasi/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'KetentuanUmum'">
    <KetentuanUmum/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKetentuanUmum'">
    <ProsesKetentuanUmum/>
  </div>
  <div class="content" v-if="route.name == 'KetentuanKhusus'">
    <KetentuanKhusus/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKetentuanKhusus'">
    <ProsesKetentuanKhusus/>
  </div>
  <div class="content" v-if="route.name == 'KetentuanKhususRDTR'">
    <KetentuanKhususRDTR/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKetentuanKhususRDTR'">
    <ProsesKetentuanKhususRDTR/>
  </div>
  <div class="content" v-if="route.name == 'PenentuanZonasi'">
    <PenentuanZonasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPenentuanZonasi'">
    <ProsesPenentuanZonasi/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'User'">
    <User/>
  </div>
  <div class="content" v-if="route.name == 'ProsesUser'">
    <ProsesUser/>
  </div>
  <div class="content" v-if="route.name == 'Regulasi'">
    <Regulasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRegulasi'">
    <ProsesRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'DaftarAduan'">
    <DaftarAduan/>
  </div>
  <div class="content" v-if="route.name == 'DaftarITR'">
    <DaftarITR/>
  </div>
  <div class="content" v-if="route.name == 'DaftarITRRDTR'">
    <DaftarITRRDTR/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Login from './components/views/Publik/Login.vue';
import Aduan from './components/views/Publik/Aduan.vue';
import ITR from './components/views/Publik/ITR.vue';
import RDTR from './components/views/Publik/RDTR.vue';
import CetakITR from './components/views/Publik/CetakITR.vue';
import CetakRDTR from './components/views/Publik/CetakRDTR.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import GIS from './components/views/Publik/GIS.vue';
import DaftarRegulasi from './components/views/Publik/DaftarRegulasi.vue';
import DaftarKetentuanUmum from './components/views/Publik/DaftarKetentuanUmum.vue';
import DaftarKetentuanKhusus from './components/views/Publik/DaftarKetentuanKhusus.vue';
import DaftarKetentuanKhususRDTR from './components/views/Publik/DaftarKetentuanKhususRDTR.vue';
import DaftarPengaturanZonasi from './components/views/Publik/DaftarPengaturanZonasi.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import KetentuanUmum from './components/views/Backoffice/KetentuanUmum/KetentuanUmum.vue';
import ProsesKetentuanUmum from './components/views/Backoffice/KetentuanUmum/ProsesKetentuanUmum.vue';
import KetentuanKhusus from './components/views/Backoffice/KetentuanKhusus/KetentuanKhusus.vue';
import ProsesKetentuanKhusus from './components/views/Backoffice/KetentuanKhusus/ProsesKetentuanKhusus.vue';
import KetentuanKhususRDTR from './components/views/Backoffice/KetentuanKhususRDTR/KetentuanKhususRDTR.vue';
import ProsesKetentuanKhususRDTR from './components/views/Backoffice/KetentuanKhususRDTR/ProsesKetentuanKhususRDTR.vue';
import PenentuanZonasi from './components/views/Backoffice/PenentuanZonasi/PenentuanZonasi.vue';
import ProsesPenentuanZonasi from './components/views/Backoffice/PenentuanZonasi/ProsesPenentuanZonasi.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';
import Regulasi from './components/views/Backoffice/Regulasi/Regulasi.vue';
import ProsesRegulasi from './components/views/Backoffice/Regulasi/ProsesRegulasi.vue';
import DaftarAduan from './components/views/Backoffice/DaftarAduan/DaftarAduan.vue';
import DaftarITR from './components/views/Backoffice/DaftarITR/DaftarITR.vue';
import DaftarITRRDTR from './components/views/Backoffice/DaftarITRRDTR/DaftarITRRDTR.vue';
import User from './components/views/Backoffice/User/User.vue';
import ProsesUser from './components/views/Backoffice/User/ProsesUser.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,Aduan,ITR,RDTR,CetakITR,CetakRDTR,KontakKami,GIS,DaftarRegulasi,DaftarKetentuanUmum,DaftarKetentuanKhusus,Dashboard
    ,Slider,ProsesSlider,InfoBeranda,ProsesInfoBeranda,DaftarAduan,DaftarITR,DaftarKetentuanKhususRDTR,DaftarPengaturanZonasi
    ,KategoriData,ProsesKategoriData,LayerPeta,ProsesLayerPeta,DaftarITRRDTR
    ,User,ProsesUser,KetentuanUmum,ProsesKetentuanUmum,KetentuanKhusus,ProsesKetentuanKhusus
    ,KetentuanKhususRDTR,ProsesKetentuanKhususRDTR,PenentuanZonasi,ProsesPenentuanZonasi
    ,Regulasi,ProsesRegulasi
  },
  mounted() {
    document.title = "Sikeren Salatiga";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
