<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
        aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
        style="margin: auto;">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    Autentifikasi Pengguna
                </div>
                <div class="modal-body">
                    <div class="cardputih4 card-outline card-outline-tabs bg-trans">
                        <div class="cardputih3-header p-0 border-bottom-0">
                            <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="custom-tabs-four-home1-tab" data-toggle="pill"
                                        href="#custom-tabs-four-home1" role="tab" aria-controls="custom-tabs-four-home1"
                                        aria-selected="true">
                                        <span>Login</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="custom-tabs-four-profile1-tab" data-toggle="pill"
                                        href="#custom-tabs-four-profile1" role="tab"
                                        aria-controls="custom-tabs-four-profile1" aria-selected="false">
                                        <span>Register</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content" id="custom-tabs-four-tabContent">
                                <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                                    aria-labelledby="custom-tabs-four-home1-tab">
                                    <p class="first-letter-besar">Mohon masukkan user dan password yang dipunyai</p>
                                    <p>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="username" id="username"
                                            :isFilled="true" v-model="username">

                                        <div class="input-group-append">
                                            <button type="button" class="btn">
                                                <i class="fas fa-arrow-right text-muted"></i>
                                            </button>
                                        </div>
                                    </div>
                                    </p>
                                    <p>
                                    <div class="input-group">
                                        <input :type="[showPasswordlogin ? 'text' : 'password']" class="form-control"
                                            placeholder="password" id="password" :isFilled="true" v-model="password">

                                        <div class="input-group-append">
                                            <button type="button" class="btn" @click="toggleShowlogin">
                                                <i
                                                    :class="{ 'fas fa-eye-slash text-muted': showPasswordlogin, 'fas fa-eye text-muted': !showPassword }"></i>
                                            </button>
                                        </div>
                                    </div>
                                    </p>
                                    <p>
                                        <center>
                                            <div class="custom-control custom-switch custom-switch-on-primary">
                                                <input type="checkbox" class="custom-control-input"
                                                    id="customSwitchAuthlogin">
                                                <label class="custom-control-label" for="customSwitchAuthlogin"
                                                    @click="rubahisilogin()" style="font-size: small;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi
                                                        Pengguna
                                                        Manusia</span>
                                                    <span v-else>Human User Authentication</span>
                                                </label>
                                            </div>
                                        </center>
                                    </p>
                                    <p>
                                        <center>
                                            <button type="btn btn-danger2 btn-block text-white" v-on:click="loginAct"
                                                :disabled="showHumanlogin == false">Login</button>&nbsp;
                                            <button type="btn btn-danger2 btn-block text-white"
                                                @click="kembali()">Batal</button>
                                        </center>
                                    </p>
                                </div>
                                <div class="tab-pane fade" id="custom-tabs-four-profile1" role="tabpanel"
                                    aria-labelledby="custom-tabs-four-profile1-tab">
                                    <p class="first-letter-besar">Mohon masukkan data sesuai identitas diri secara
                                        lengkap dan tepat</p>
                                    <p>
                                    <div class="input-group">
                                        <input type="number" class="form-control" placeholder="nik" id="nikbaru"
                                            :isFilled="true" v-model="nikbaru">

                                        <div class="input-group-append">
                                            <button type="button" class="btn">
                                                <i class="fas fa-arrow-right text-muted"></i>
                                            </button>
                                        </div>
                                    </div>
                                    </p>
                                    <p>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="nama" id="namabaru"
                                            :isFilled="true" v-model="namabaru">

                                        <div class="input-group-append">
                                            <button type="button" class="btn">
                                                <i class="fas fa-arrow-right text-muted"></i>
                                            </button>
                                        </div>
                                    </div>
                                    </p>
                                    <p>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="alamat" id="alamatbaru"
                                            :isFilled="true" v-model="alamatbaru">

                                        <div class="input-group-append">
                                            <button type="button" class="btn">
                                                <i class="fas fa-arrow-right text-muted"></i>
                                            </button>
                                        </div>
                                    </div>
                                    </p>
                                    <p>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="username" id="usernamebaru"
                                            :isFilled="true" v-model="usernamebaru">

                                        <div class="input-group-append">
                                            <button type="button" class="btn">
                                                <i class="fas fa-arrow-right text-muted"></i>
                                            </button>
                                        </div>
                                    </div>
                                    </p>
                                    <p>
                                        <label>Password diharapkan memakai kombinasi huruf besar, angka dan spesial
                                            karakter</label>
                                    <div class="input-group">

                                        <input :type="[showPasswordbaru ? 'text' : 'password']" class="form-control"
                                            placeholder="password" id="password" :isFilled="true" v-model="passwordbaru"
                                            @keyup="cekkekuatan()">

                                        <div class="input-group-append">
                                            <button type="button" class="btn" @click="toggleShowbaru">
                                                <i
                                                    :class="{ 'fas fa-eye-slash text-muted': showPassword, 'fas fa-eye text-muted': !showPassword }"></i>
                                            </button>
                                        </div>
                                    </div>
                                    </p>
                                    <p>
                                    <div class="col-sm-12">
                                        <label>Kekuatan kata sandi</label>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                id="persennyapassword">
                                                <span id="tulisanpersennyapassword">0%</span>
                                            </div>
                                        </div>
                                    </div>
                                    </p>
                                    <p>
                                        <center>
                                            <div class="custom-control custom-switch custom-switch-on-primary">
                                                <input type="checkbox" class="custom-control-input"
                                                    id="customSwitchAuthbaru">
                                                <label class="custom-control-label" for="customSwitchAuthbaru"
                                                    @click="rubahisibaru()" style="font-size: small;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi
                                                        Pengguna
                                                        Manusia</span>
                                                    <span v-else>Human User Authentication</span>
                                                </label>
                                            </div>
                                        </center>
                                    </p>
                                    <p>
                                        <center>
                                            <button type="btn btn-danger2 btn-block text-white" v-on:click="registerAct"
                                                :disabled="showHumanbaru == false">Register</button>&nbsp;
                                            <button type="btn btn-danger2 btn-block text-white"
                                                @click="kembali()">Batal</button>
                                        </center>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- /.card -->
                    </div>

                </div>
                <div class="modal-footer justify-content-between">

                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 pmisputih" data-wow-delay="0.1s">
                INFORMASI TATA RUANG
            </h3>
            <ol class="breadcrumb justify-content-center mb-0" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    Layanan Informasi Tata Ruang
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="col-sm-12">&nbsp;</div>
    <button type="button" class="btn btn-tool" id="koordinat" @click="koordinatkosong()" style="display: none;"><i
            class="fas fa-minus text-white"></i></button>
    <div class="container-fluid bg-breadcrumbberanda px-5">
        <div class="row">
            <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                <li class="nav-item" style="display: none;">
                    <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                        href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                        aria-selected="true">
                        Data Pemohon
                    </a>
                </li>
                <li class="nav-item" style="display: none;">
                    <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                        href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                        aria-selected="false">
                        Data Lokasi
                    </a>
                </li>
                <li class="nav-item" style="display: none;">
                    <a class="nav-link" id="custom-tabs-four-risk-tab" data-toggle="pill" href="#custom-tabs-four-risk"
                        role="tab" aria-controls="custom-tabs-four-risk" aria-selected="false">
                        Kirim Data
                    </a>
                </li>
            </ul>
            <div class="tab-content" id="custom-tabs-four-tabContent">
                <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                    aria-labelledby="custom-tabs-four-home-tab">
                    <div class="row px-2">
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        DATA PEMOHON
                                    </h4>
                                </div>
                            </div>
                            <div class="col-sm-12 text-center">
                                <img src="../../../../src/assets/img/berandaNgumahNew.png" height="160">
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="help-block text-center wow fadeInLeft">
                                Silahkan masukkan identitas, silahkan mengisikan data
                                identitas diri secara lengkap dan tepat. Kekeliruan mengisikan data identitas
                                mengakibatkan kesalahan informasi dalam pencetakan ITR secara mandiri.
                            </div>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>NIK <small class="text-info"> (*wajib diisi angka)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-address-book"></i></span>
                                </div>
                                <input type="number" class="form-control form-control-sm" id="nik" name="nik"
                                    placeholder="NIK" v-model="nik" readonly>
                            </div>
                        </div>

                        <div class="col-sm-6 float-left pb-2">
                            <label>Nama <small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="nama" name="nama"
                                    placeholder="Nama" v-model="nama" readonly>
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Alamat <small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-address-card"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="alamat" name="alamat"
                                    placeholder="Alamat" v-model="alamat" readonly>
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Alamat Lokasi Permohonan <small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-sticky-note"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="alamatlokasi"
                                    name="alamatlokasi" placeholder="Alamat Lokasi Permohonan" v-model="alamatlokasi">
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Nomor Sertifikat <small class="text-info"> (*wajib diisi)</small></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-sticky-note"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="sertifikat"
                                    name="sertifikat" placeholder="Nomor Sertifikat" v-model="sertifikat">
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Jenis Izin <small class="text-info"> (*wajib dipilih)</small></label>
                            <v-select :options="jenisijin" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelectedjenisijin" @update:modelValue="onjenisijinChange"
                                style="background-color: #ffffff;"></v-select>
                        </div>
                        <div class="col-sm-12 float-left pb-2">
                            <label>Rencana Kegiatan <small class="text-info"> (*wajib dipilih)</small></label>
                            <v-select :options="kegiatan" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelectedkegiatan" @update:modelValue="onkegiatanChange"
                                style="background-color: #ffffff;"></v-select>
                        </div>
                        <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                        <div class="col-sm-5 float-left pb-2"></div>
                        <div class="col-sm-2 float-left pb-2">
                            <button @click="bukapeta()" class="btn btn-secondary btn-block text-white"
                                placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                title="Kirim aduan.." style="color: black;">
                                <b><i class="far fa-arrow-alt-circle-right"></i>
                                    Selanjutnya
                                </b>
                            </button>
                        </div>
                        <div class="col-sm-5 float-left pb-2"></div>
                    </div>
                </div>
                <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                    aria-labelledby="custom-tabs-four-profile-tab">
                    <div class="col-sm-12">&nbsp;</div>
                    <div class="container-fluid feature py-3">
                        <div class="section-title mb-0">
                            <div class="sub-style">
                                <h4 class="sub-title px-3 mb-0">
                                    DATA LOKASI
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="help-block text-center">
                            Silahkan lakukan digitasi dengan menekan tombol "Mulai Digitasi", silahkan klik pada peta
                            untuk menentukan titik batas luar daerah yang dimohon, selesaikan dengan menekan "Selesai
                            Digitasi"
                        </div>
                    </div>
                    <div class="col-sm-12">&nbsp;</div>
                    <div class="row px-0">
                        <input type="number" id="latitude_tmp" class="form-control form-control-sm"
                            style="display: none;">
                        <input type="number" id="longitude_tmp" class="form-control form-control-sm"
                            style="display: none;">
                        <div class="col-sm-10">
                            <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 95vh;"
                                frameborder="0" id="maps" scrolling="no" @load="loadpeta()"></iframe>
                        </div>
                        <div class="col-sm-2" style="background-color: #ffffff;max-height: 95vh;overflow: auto;">
                            <div class="card-header text-center">
                                Koordinat Digitasi
                                <button type="button" class="btn btn-info btn-sm text-white" id="kurang"
                                    @click="deleteKoordinat()" style="display: none;">
                                    <i class="far fa-minus-square"></i> Hapus Marker
                                </button>
                            </div>
                            <button type="button" class="btn btn-info btn-sm" id="tambah" @click="addKoordinat()"
                                style="display: none;">
                                <i class="far fa-plus-square"></i>
                            </button>
                            <div class="col-sm-12 previous" v-for="(koodinatsetuju, counter) in koodinatsetujus"
                                v-bind:key="counter">
                                <div class="row">
                                    <div class="row">
                                        <div class="col-sm-10 text-left">
                                            <label>Koordinat Ke {{ counter + 1 }}</label>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Latitude </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-map-marker"></i></span>
                                                </div>
                                                <input type="number" :id="'lat_setuju' + (counter + 1)"
                                                    class="form-control form-control-sm" step="0.0000001"
                                                    v-model="koodinatsetuju.latitude">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Longitude </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-map-marker-alt"></i></span>
                                                </div>
                                                <input type="number" :id="'longi_setuju' + (counter + 1)"
                                                    class="form-control form-control-sm" step="0.0000001"
                                                    v-model="koodinatsetuju.longitude">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">&nbsp;</div>
                        <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                        <div class="col-sm-4 float-left pb-2"></div>
                        <div class="col-sm-2 float-left pb-2">
                            <button @click="bukapemohon()" class="btn btn-secondary btn-block text-white"
                                placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                title="Kirim aduan.." style="color: black;">
                                <b><i class="far fa-arrow-alt-circle-left"></i>
                                    Sebelumnya
                                </b>
                            </button>
                        </div>
                        <div class="col-sm-2 float-left pb-2">
                            <button @click="bukakirim()" class="btn btn-secondary btn-block text-white"
                                placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                title="Kirim aduan.." style="color: black;">
                                <b><i class="far fa-arrow-alt-circle-right"></i>
                                    Selanjutnya
                                </b>
                            </button>
                        </div>
                        <div class="col-sm-4 float-left pb-2"></div>
                    </div>
                </div>
                <div class="tab-pane fade" id="custom-tabs-four-risk" role="tabpanel"
                    aria-labelledby="custom-tabs-four-risk-tab">
                    <div class="col-sm-12">&nbsp;</div>
                    <div class="container-fluid feature py-3">
                        <div class="section-title mb-0">
                            <div class="sub-style">
                                <h4 class="sub-title px-3 mb-0">
                                    KONFIRMASI DATA
                                </h4>
                            </div>
                        </div>
                        <div class="col-sm-12 text-center">
                            <img src="../../../../src/assets/img/berandaNgumahNew.png" height="160">
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-sm-6 float-left pb-2">
                            <label>Kode Keamanan</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-clipboard-list"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="capcha" name="capcha"
                                    placeholder="Kode Keamanan" v-model="capcha" disabled>
                            </div>
                        </div>
                        <div class="col-sm-6 float-left pb-2">
                            <label>Ulangi Kode Keamanan</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="far fa-clone"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-sm" id="capchainput"
                                    name="capchainput" placeholder="Ulangi Kode Keamanan" v-model="capchainput"
                                    style="text-transform:uppercase">
                            </div>
                        </div>
                        <div class="col-sm-12 float-left pb-2">
                            <div class="lockscreen-item" style="background: transparent;">
                                <button @click="bukapeta()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                    title="Kirim aduan.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-left"></i>
                                        Sebelumnya
                                    </b>
                                </button>
                                <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-sm-12 text-center">{{ selamat }}</div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-sm-12 text-center">{{ tulisregister }}</div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="input-group px-5 py-5" style="border: solid 1px #cccccc;">
                                    <div class="custom-control custom-switch custom-switch-on-primary">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                                        <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                                            style="font-size: small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna
                                                Manusia</span>
                                            <span v-else>Human User Authentication</span>
                                        </label>
                                    </div>
                                    <div class="help-block text-center">&nbsp;</div>
                                    <button class="btn btn-danger2 btn-block text-white" placeholder="Username"
                                        data-toggle="tooltip" data-placement="left" title="Kirim aduan.."
                                        style="color: black;" v-on:click="itrAct" :disabled="showHuman == false">
                                        <b><i class="	fas fa-mail-bulk"></i>
                                            Kirim
                                        </b>
                                    </button>
                                    <div class="col-sm-12">&nbsp;</div>
                                </div>

                            </div>
                            <!-- /.lockscreen-item -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">&nbsp;</div>
            <div class="col-sm-12">&nbsp;</div>

        </div>
    </div>


    <div class="help-block text-center">&nbsp;</div>
    <div class="content-header">
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";


export default {
    components: {
        Top, Footer, swal, vSelect
    },
    data() {
        return {
            halamanloading: true,
            tokenuser: JSON.parse(localStorage.getItem("tokensistempublik")),
            iduser: JSON.parse(localStorage.getItem("usistempublik")),
            leveluser: JSON.parse(localStorage.getItem("lsistempublik")),
            namauser: JSON.parse(localStorage.getItem("nmusistempublik")),
            tahun: new Date().getFullYear(),
            nama: "", nik: "", alamat: "", alamatlokasi: "",
            rencanakegiatan: "", sertifikat: "",
            capcha: this.makeid(5), capchainput: "",
            showHuman: false,
            showPassword: false,
            bahasa: localStorage.getItem("bahasasistem"),
            statusvalidasipemohon: false,
            linknya: process.env.VUE_APP_URL_API,
            secretencData: 'Kosong',
            counter: 0,
            koodinatsetujus: [],
            selamat: '',
            tulisregister: '',
            datapesan: '',

            jenisijin: [],
            datajenisijin: [],
            defaultSelectedjenisijin: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedjenisijin: 0,

            kegiatan: [],
            datakegiatan: [],
            defaultSelectedkegiatan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedkegiatan: 0,
            username: "", password: "",
            showHumanlogin: false,
            showPasswordlogin: false,
            usernamebaru: "",
            passwordbaru: "",
            showHumanbaru: false,
            showPasswordbaru: false,
            nikbaru: "",
            namabaru: "",
            alamatbaru: "",
            strength: 0,
        };
    },
    methods: {
        async loginAct() {
            const data = {
                username: this.username,
                katakunci: this.password
            };
            if ((this.username == "") || (this.password == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuthlogin').checked = false;
                document.getElementById("username").focus();
                this.showHuman = false;
                return false;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovationloginpengguna-login", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        this.showHuman = false;
                        document.getElementById('customSwitchAuthlogin').checked = false;
                        return false;

                    }
                    else {
                        var token = JSON.stringify(Response.data.accessToken);
                        const mainAPIUser = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                Authorization: "Bearer " + Response.data.accessToken,
                            },
                        });
                        mainAPIUser.get("parastapainnovationUser-GetUser?kduser=" + Response.data.content.data[0].tmpsys + "&level=" + Response.data.content.data[0].level).then(
                            Res => {
                                if (Res.data.response == 'error') {
                                    swal.mixin({
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000
                                    }).fire({
                                        icon: 'warning',
                                        title: Res.data.message,
                                    });
                                    document.getElementById("username").focus();
                                    this.showHuman = false;
                                    document.getElementById('customSwitchAuthlogin').checked = false;
                                    return false;

                                }
                                else {
                                    //alert(JSON.stringify(Response.data.accessToken))
                                    localStorage.setItem("tokensistempublik", token);
                                    localStorage.setItem("usistempublik", JSON.stringify(Response.data.content.data[0].tmpsys));
                                    localStorage.setItem("lsistempublik", JSON.stringify(Res.data.content.data[0].level));
                                    localStorage.setItem("nmusistempublik", JSON.stringify(Res.data.content.data[0].nama));
                                    localStorage.setItem("tsistem", new Date().toString());
                                    this.nik = Res.data.content.data[0].nip;
                                    this.nama = Res.data.content.data[0].nama
                                    this.alamat = Res.data.content.data[0].alamat;
                                    $('#costumModaldisclaimer').modal('hide');
                                }
                            }
                        ).catch(
                            error => {
                                swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                }).fire({
                                    icon: 'warning',
                                    title: error,
                                });
                                this.showHuman = false;
                                document.getElementById('customSwitchAuthlogin').checked = false;
                                return false;
                            }
                        )
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    this.showHuman = false;
                    document.getElementById('customSwitchAuthlogin').checked = false;
                    return false;
                }
            )
        },
        async registerAct() {
            const data = {
                username: this.usernamebaru,
                katakunci: this.passwordbaru,
                nama: this.namabaru,
                nik: this.nikbaru,
                alamat: this.alamatbaru,
                level: 7,
            };
            if ((this.usernamebaru == "") || (this.passwordbaru == "") || (this.namabaru == "") || (this.nikbaru == "") || (this.alamatbaru == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuthbaru').checked = false;
                document.getElementById("usernamebaru").focus();
                this.showHumanbaru = false;
                return false;
            }
            if (this.strength < 80) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Kekuatan password kurang kuat minimal 80..',
                });
                this.showHumanbaru = false;
                return false;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovationregister-Register", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        this.showHumanbaru = false;
                        document.getElementById('customSwitchAuthbaru').checked = false;
                        return false;

                    }
                    else {
                        swal.fire({
                            title: "Konfirmasi",
                            icon: 'success',
                            title: Response.data.message,
                            showCancelButton: false,
                            cancelButtonText: "Batal",
                            confirmButtonColor: "#2176e6",
                            cancelButtonColor: "#ffaa05",
                            confirmButtonText: "OK"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.replace("/RDTR");
                            }
                        });

                        return false;
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    this.showHuman = false;
                    document.getElementById('customSwitchAuth').checked = false;
                    return false;
                }
            )
        },
        cekkekuatan() {
            //alert(this.passwordbaru);
            this.strength = 0;
            var lowerCase = this.passwordbaru.match(/[a-z]/);
            var upperCase = this.passwordbaru.match(/[A-Z]/);
            var numbers = this.passwordbaru.match(/[0-9]/);
            var width = this.passwordbaru.length;
            var specialCharacters = this.passwordbaru.match(/[\!\~\@\&\#\$\%\^\&\*\(\)\{\}\?\-\_\+\=]/);
            if (width > 8) {
                this.strength = this.strength + 20;
            }
            if (lowerCase) {
                this.strength = this.strength + 20;
            }
            if (upperCase) {
                this.strength = this.strength + 20;
            }
            if (numbers) {
                this.strength = this.strength + 20;
            }
            if (specialCharacters) {
                this.strength = this.strength + 20;
            }
            document.getElementById('persennyapassword').style.width = this.strength + "%";
            document.getElementById('tulisanpersennyapassword').innerHTML = this.strength + "%";
        },
        toggleShowlogin() {
            this.showPasswordlogin = !this.showPasswordlogin;
        },
        toggleShowbaru() {
            this.showPasswordbaru = !this.showPasswordbaru;
        },
        rubahisilogin() {
            if (this.showHumanlogin == false) {
                this.showHumanlogin = true;
            }
            else {
                this.showHumanlogin = false;
            }
        },
        rubahisibaru() {
            if (this.showHumanbaru == false) {
                this.showHumanbaru = true;
            }
            else {
                this.showHumanbaru = false;
            }
        },
        kembali() {
            window.location.replace("/");
        },
        getOtentifikasi() {
            if ((localStorage.getItem("tsistem")== null) || (localStorage.getItem("tsistem")== '') || (localStorage.getItem("tsistem")== undefined)) {
                this.ClearlocalStoragePublik();
                $('#costumModaldisclaimer').modal('show');
                return false;
            }
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStoragePublik();
                $('#costumModaldisclaimer').modal('show');
                return false;
            }
            if ((this.iduser == null) || (this.leveluser == null) || (this.namauser == null)) {
                this.ClearlocalStoragePublik();
                $('#costumModaldisclaimer').modal('show');
                return false;
            }
            
            const mainAPIUser = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + JSON.parse(localStorage.getItem("tokensistempublik")),
                },
            });
            mainAPIUser.get("parastapainnovationUser-GetUser?kduser=" + JSON.parse(localStorage.getItem("usistempublik")) + "&level=" + JSON.parse(localStorage.getItem("lsistempublik"))).then(
                Res => {
                    if (Res.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Res.data.message,
                        });
                        this.ClearlocalStoragePublik();
                        $('#costumModaldisclaimer').modal('show');
                        document.getElementById("username").focus();
                        this.showHuman = false;
                        document.getElementById('customSwitchAuthlogin').checked = false;
                        return false;

                    }
                    else {
                        this.nik = Res.data.content.data[0].nip;
                        this.nama = Res.data.content.data[0].nama
                        this.alamat = Res.data.content.data[0].alamat;
                        $('#costumModaldisclaimer').modal('hide');
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    this.ClearlocalStoragePublik();
                    $('#costumModaldisclaimer').modal('show');
                    this.showHuman = false;
                    document.getElementById('customSwitchAuthlogin').checked = false;
                    return false;
                }
            )
        },
        onjenisijinChange(a) {
            try {
                this.selectedjenisijin = this.jenisijin[a].tag;
            }
            catch (err) {
                return;
            }
            this.ambilreffkegiatanterpilih();
        },
        onkegiatanChange(a) {
            try {
                this.selectedkegiatan = this.kegiatan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        koordinatkosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Koordinat belum diisi..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        addKoordinat() {
            var lat = document.getElementById('latitude_tmp').value;
            var lon = document.getElementById('longitude_tmp').value;
            this.koodinatsetujus.push({
                previous: '',
                expiration: '',
                "latitude": lat,
                "longitude": lon,
            })
        },
        deleteKoordinat() {
            this.koodinatsetujus.splice(this.counter, 1);
        },
        bukapemohon() {
            var iFrame = document.getElementById('maps');
            var tombol = iFrame.contentWindow.document.getElementById('juduldigitasi').innerHTML;
            if (tombol == 'Selesai Digitasi') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Selesaikan digitasi terlebih dahulu..',
                });
                return false;
            }
            $('#custom-tabs-four-home-tab').trigger('click');
        },
        bukakirim() {
            var iFrame = document.getElementById('maps');
            var tombol = iFrame.contentWindow.document.getElementById('juduldigitasi').innerHTML;
            if (tombol == 'Selesai Digitasi') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Selesaikan digitasi terlebih dahulu..',
                });
                return false;
            }

            var JumlahKoordinat = this.koodinatsetujus.length;
            if (JumlahKoordinat == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Lakukan Digitasi terlebih dahulu..',
                });
                return false;
            }

            $('#custom-tabs-four-risk-tab').trigger('click');
        },
        bukapeta() {
            if (this.nik == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom nik..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nik").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.nama == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom nama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nama").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.alamat == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom alamat..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("alamat").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.alamatlokasi == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom alamat lokasi..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("alamatlokasi").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.sertifikat == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom sertifikat..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("sertifikat").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selectedjenisijin == '') || (this.selectedjenisijin === null) || (this.selectedjenisijin === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Jenis Izin..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selectedkegiatan == '') || (this.selectedkegiatan === null) || (this.selectedkegiatan === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Rencana Kegiatan..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            $('#custom-tabs-four-profile-tab').trigger('click');
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        async itrAct() {
            if (this.nik == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom nik..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nik").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.nama == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom nama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("nama").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.alamat == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom alamat..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("alamat").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.alamatlokasi == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom alamat lokasi..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("alamatlokasi").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }

            if (this.sertifikat == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom sertifikat..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("sertifikat").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selectedjenisijin == '') || (this.selectedjenisijin === null) || (this.selectedjenisijin === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Jenis Izin..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if ((this.selectedkegiatan == '') || (this.selectedkegiatan === null) || (this.selectedkegiatan === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Rencana Kegiatan..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            var JumlahKoordinat = this.koodinatsetujus.length;
            if (JumlahKoordinat == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Lakukan Digitasi terlebih dahulu..',
                });
                return false;
            }
            if (this.capchainput == "") {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom capcha..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }
            if (this.capchainput.toUpperCase() != this.capcha) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Kode Keamanan Tidak Sama..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("capchainput").focus();
                this.showHuman = false;
                document.getElementById('customSwitchAuth').checked = false;
                return false;
            }

            var polyCoords = '';
            var polyCoordsawal = '';
            for (let i = 0; i < this.koodinatsetujus.length; i++) {
                if (i == 0) {
                    polyCoords = polyCoords + this.koodinatsetujus[i].longitude + ' ' + this.koodinatsetujus[i].latitude;
                    polyCoordsawal = ',' + this.koodinatsetujus[i].longitude + ' ' + this.koodinatsetujus[i].latitude;
                }
                else {
                    polyCoords = polyCoords + ',' + this.koodinatsetujus[i].longitude + ' ' + this.koodinatsetujus[i].latitude;
                }
            }
            var polypoly = 'POLYGON((' + polyCoords + polyCoordsawal + '))';

            var tahunregister = this.pad2(new Date().getFullYear());
            var bulanregister = this.pad2(new Date().getMonth() + 1);
            var tanggalregister = this.pad2(new Date().getDate());
            var jamregister = this.pad2(new Date().getHours());
            var menitregister = this.pad2(new Date().getMinutes());
            var detikregister = this.pad2(new Date().getSeconds());

            var koderegistrasi = this.makeid(5) + tahunregister + bulanregister + tanggalregister + jamregister + menitregister + detikregister;

            const data = {
                nama: this.nama,
                nik: this.nik,
                alamat: this.alamat,
                alamatlokasi: this.alamatlokasi,
                ijin: this.selectedjenisijin,
                rencanakegiatan: this.selectedkegiatan,
                sertifikat: this.sertifikat,
                ogc_geom: polypoly,
                koderegistrasi: koderegistrasi,
            };
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer SiKerenITRGPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2j2a098002",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovationMasyarakat_rdtr-rdtr", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        return false;

                    }
                    else {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'success',
                            title: Response.data.message,
                        });
                        this.selamat = 'Data berhasil disimpan, silahkan mencetak ITR melalui menu LAYANAN TATA RUANG --> CARI / CETAK PERMOHONAN ITR dengan memasukan nomor register anda..';
                        this.tulisregister = 'Kode Registrasi anda adalah: ' + koderegistrasi;
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        return false;
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    document.getElementById('customSwitchAuth').checked = false;
                    this.showHuman = false;
                    return false;
                }
            )
        },
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya + '|' + this.secretencData;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/iteer.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        async ambilreff() {
            this.halamanloading = true;
            const mainAPI8 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer SiKerenITRGPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2j2a098002",
                },
            });
            var random = Math.random();
            await mainAPI8.get("parastapainnovation_masterijin-MasterIjin?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajenisijin = [];
                        this.jenisijin = [];
                        this.datajenisijin = Response.data.content.data;
                        this.datajenisijin.forEach((item) => {
                            this.jenisijin.push({
                                label: item.nama,
                                code: this.datajenisijin.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jenisijin.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                //console.log(error);
                this.JustClearlocalStorage();
                this.halamanloading = false;
            });

            await mainAPI8.get("parastapainnovation_masterkegiatan-MasterKegiatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakegiatan = [];
                        this.kegiatan = [];
                        this.datakegiatan = Response.data.content.data;
                        this.datakegiatan.forEach((item) => {
                            this.kegiatan.push({
                                label: '(' + item.kbli + ') ' + item.kegiatan,
                                code: this.datakegiatan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kegiatan.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                //console.log(error);
                this.JustClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async ambilreffkegiatanterpilih() {
            this.halamanloading = true;
            const mainAPI8 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer SiKerenITRGPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2j2a098002",
                },
            });
            var random = Math.random();
            await mainAPI8.get("parastapainnovation_masterkegiatan-MasterKegiatan?random=" + random + "&ijin=" + this.selectedjenisijin).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakegiatan = [];
                        this.kegiatan = [];
                        this.datakegiatan = Response.data.content.data;
                        this.datakegiatan.forEach((item) => {
                            this.kegiatan.push({
                                label: '(' + item.kbli + ') ' + item.kegiatan,
                                code: this.datakegiatan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kegiatan.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                //console.log(error);
                this.JustClearlocalStorage();
                this.halamanloading = false;
            });
        }
    },
    mounted() {
        
        this.ambilreff();
        try {
            document.getElementById("nik").focus();
            this.showHuman = false;
            this.JustClearlocalStorage();
        } catch (error) {

        }
        //this.ClearlocalStoragePublik();
        this.getOtentifikasi();
        //alert(this.tokenuser);
        //alert(localStorage.getItem("tokensistempublik"));

    },
}
</script>

<style></style>